import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { trackPageView } from "../services/PaidTrafic";
import { isAuthenticated } from "../services/Auth";
import Loading from "../components/Application/Loading";

const LoginScreen = lazy(() => import("../screens/Auth/Login"));
const UpdatePasswordScreen = lazy(() => import("../screens/Auth/UpdatePassword"));
const UpdateUserDataScreen = lazy(() => import("../screens/Auth/UpdateUserData"));
const SignUpScreen = lazy(() => import("../screens/Auth/SignUp"));
const ForgotPasswordScreen = lazy(() => import("../screens/Auth/ForgotPassword"));
const ResetPasswordScreen = lazy(() => import("../screens/Auth/ResetPassword"));
const StatisticsScreen = lazy(() => import("../screens/Statistics/Statistics"));
const StudyTypeSelectScreen = lazy(() => import("../screens/Questions/StudyTypeSelect"));
const SelfGuidedStudyScreen = lazy(() => import("../screens/Questions/StudyTypes/SelfGuidedStudy"));
const DrivenStudyScreen = lazy(() => import("../screens/Questions/StudyTypes/DrivenStudy/DrivenStudy"));
const UserDrivenStudyConfigsScreen = lazy(() => import("../screens/Questions/StudyTypes/DrivenStudy/UserDrivenStudyConfigs"));
const NotFoundPageScreen = lazy(() => import("../screens/Errors/NotFoundPage"));
const SubscriptionScreen = lazy(() => import("../screens/Subscription/Subscription"));
const CheckoutScreen = lazy(() => import("../screens/Checkout/Checkout"));
const Index = lazy(() => import("../screens/LandingPage/Index"));
const Terms = lazy(() => import("../screens/LandingPage/Terms"));
const VideosScreen = lazy(() => import("../screens/Videos/VideosScreen"));
const NewsScreen = lazy(() => import("../screens/News/News"));

const Authenticator = ({ children }) =>
  isAuthenticated() ? <Navigate to={"/"} /> : children;

const Pvt = ({ children, useBackUrl }) => {
  const location = useLocation();
  if (isAuthenticated()) {
    return children;
  } else {
    if (useBackUrl) {
      return <Navigate to={`/login?back_url=${location.pathname}`} />;
    } else {
      return <Navigate to={"/login"} />;
    }
  }
};

const RootRoutes = () => {
  let location = useLocation();

  React.useEffect(() => {
    trackPageView();
  }, [location]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/login"
          element={
            <Authenticator>
              <LoginScreen />
            </Authenticator>
          }
        />
        <Route
          path="/sign-up"
          element={
            <Authenticator>
              <SignUpScreen />
            </Authenticator>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <Authenticator>
              <ForgotPasswordScreen />
            </Authenticator>
          }
        />
        <Route
          path="/reset-password"
          element={
            <Authenticator>
              <ResetPasswordScreen />
            </Authenticator>
          }
        />
        <Route path="/">
          <Route
            index
            element={
              isAuthenticated() ? (
                <Pvt>
                  <SelfGuidedStudyScreen />
                </Pvt>
              ) : (
                <Index />
              )
            }
          />
          <Route
            path="videos/:id"
            element={
              <Pvt>
                <VideosScreen />
              </Pvt>
            }
          />
          <Route
            path="update-password"
            element={
              <Pvt>
                <UpdatePasswordScreen />
              </Pvt>
            }
          />
          <Route
            path="update-user-data"
            element={
              <Pvt>
                <UpdateUserDataScreen />
              </Pvt>
            }
          />
          <Route
            path="statistics"
            element={
              <Pvt>
                <StatisticsScreen />
              </Pvt>
            }
          />
          <Route
            path="news"
            element={
              <Pvt>
                <NewsScreen />
              </Pvt>
            }
          />
        </Route>
        <Route path="/questions">
          <Route
            index
            element={
              <Pvt>
                <StudyTypeSelectScreen />
              </Pvt>
            }
          />
          <Route
            path="self-guided-study"
            element={
              <Pvt>
                <SelfGuidedStudyScreen />
              </Pvt>
            }
          />
          <Route path="driven-study">
            <Route
              index
              element={
                <Pvt>
                  <DrivenStudyScreen />
                </Pvt>
              }
            />
            <Route
              path="configs"
              element={
                <Pvt>
                  <UserDrivenStudyConfigsScreen />
                </Pvt>
              }
            />
          </Route>
        </Route>
        <Route path="/subscription">
          <Route
            index
            element={
              <Pvt useBackUrl={true}>
                <SubscriptionScreen />
              </Pvt>
            }
          />
        </Route>
        <Route path="/checkout">
          <Route
            path="plan/:plan_id"
            element={
              <Pvt useBackUrl={true}>
                <CheckoutScreen />
              </Pvt>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            isAuthenticated() ? (
              <NotFoundPageScreen />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route exact path="/termos" element={<Terms />} />
      </Routes>
    </Suspense>
  );
};

export default RootRoutes;
